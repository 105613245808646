import React from "react"
import Helmet from 'react-helmet';
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SubjectLink from "../components/subject-link"

import HeroHeader from "../components/heroHeader"



const IndexPage = ({
  data: {
    site,
    allMarkdownRemark: { edges },
  },
}) => {

  // const Posts = edges
  //   .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
  //   .map(edge => <PostLink key={edge.node.id} post={edge.node} />)



  const Subjects = edges
    .filter(edge => edge.node.frontmatter.category === 'book') // You can filter your posts based on some criteria
    .map(edge => <SubjectLink key={edge.node.id} post={edge.node} />)




  return (
    <Layout>
      <Helmet>
        <title>{site.siteMetadata.title}</title>
        <meta name="description" content={site.siteMetadata.description} />
      </Helmet>
      <HeroHeader/>
      <h2>Blog Posts &darr;</h2>
      <h1>Articals</h1>
      <div className="grids">
      </div>
      <h1>Lab Manual</h1>
      <div className="grids">
      </div>
      <h1>Books</h1>
      <div className="grids">
        {Subjects}
      </div>
    </Layout>
  )
}

export default IndexPage
export const pageQuery = graphql`
  query subchapter {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            thumbnail
            category
          }
        }
      }
    }
  }
`
